<template>
  <div class="admin-confirm-ui" :class="{'is-active': visible}" id="confirm-delete">
    <div class="admin-confirm-ui__overlay">
      <div class="admin-confirm-ui__inner">
        <div class="admin-confirm-ui__icon">
          <img src="@/assets/images/icon-exclamation.svg" alt="">
        </div>
        <div class="admin-confirm-ui__right">
          <div class="admin-confirm-ui__content">
            <h2>{{title}}</h2>
            <p>{{message}}</p>
          </div>
          <button class="admin-confirm-ui__btn-cancel" @click="onCancel()">取消</button>
          <button class="admin-confirm-ui__btn-delete" @click="onConfirm()">確認</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'ConfirmDeleteDialog',
  props: ['title', 'message', 'visible'],
  emits: ['confirm'],
  methods: {
    onCancel () {
      this.$emit('cancel')
    },
    onConfirm () {
      this.$emit('confirm')
    }
  }
}
</script>
